<template>
    <div class="footer-basic">
        <footer>
          <div class="social">
            <a href="https://www.instagram.com/_nftworldnews_/">
                <img class="imgIcon" src="../assets/icons/instagram-brands.svg">
            </a>
            <a href="https://discord.gg/GsWphCqHer">
              <img class="imgIcon" src="../assets/icons/discord-brands.svg">
            </a>
            <a href="https://twitter.com/_NFTWorldNews_">
              <img class="imgIcon" src="../assets/icons/twitter-brands.svg">
            </a>
            <a href="https://medium.com/@nftworldnews">
              <img class="imgIcon" src="../assets/icons/medium.svg">
            </a>
          </div>
          <ul class="list-inline">
            <li class="list-inline-item">
              <a @click="goto('storyRef')">Story</a>
            </li>
            <li class="list-inline-item">
              <a @click="goto('roadmapRef')">Roadmap</a>
            </li>
            <li class="list-inline-item">
              <a @click="goto('teamRef')">Team</a>
            </li>
            <!--<li class="list-inline-item">
              <a @click="goto('mintRef')">Mint</a>
            </li>
            <li class="list-inline-item">
              <a @click="goto('outlookRef')">Outlook</a>
            </li>-->
          </ul>
          <p class="copyright">NFT WORLD NEWS © 2022</p>
        </footer>
    </div>
</template>

<script>
export default {
  name: 'Footer',
  props: [],
  methods: {
     goto(refName) {
      this.$emit("goto", refName);
    }
  },
  mounted() {}
}
</script>

<style scoped>
.imgIcon{
    width: 20px;
}

.footer-basic {
  padding: 40px 0;
  background-color: #4b4c4d;
  color: white;
}

.list-inline-item{
  cursor: pointer;
}

.list-inline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.footer-basic ul {
  padding: 0;
  list-style: none;
  text-align: center;
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 0;
}

.footer-basic li {
  padding: 0 10px;
}

.footer-basic ul a {
  color: inherit;
  text-decoration: none;
}

.footer-basic ul a:hover {
  font-weight: bold;
}

.footer-basic .social {
  text-align: center;
}

.footer-basic .social > a {
  font-size: 24px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  color: #4b4c4d;
}


.footer-basic .copyright {
  margin-top: 15px;
  text-align: center;
  font-size: 13px;
  color: white;
  margin-bottom: 0;
}

</style>
